import { combineReducers } from "redux";
// import LoginReducer from "./login";
// import ProfileReducer from "./profile";
// import RegisterReducer from "./register";

export default combineReducers({
  //   LoginReducer,
  //   ProfileReducer,
  //   RegisterReducer,
});
